<template>
  <div>
    <content-block>
      <div class="pos">
        <div class="countBox">工单计数：{{ workOrderNum }}</div>
        <div class="countBox">备件合计：{{ sparePartNum }}</div>
        <el-date-picker
          class="timerRange"
          v-model="dateRange"
          type="daterange"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleSearch"
        ></el-date-picker>
      </div>
    </content-block>
    <table-with-pagination
      :tableData="tableData"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import { dateFormat } from '@/utils/dateFormat.js';
import { getCountList, getCount } from '@/api/spareParts';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import contentBlock from '../../../../components/common/layout/contentBlock.vue';

export default {
  components: { contentBlock },
  name: 'RECORDS_TABLE',
  mixins: [TablePaginationMixin],
  props: {
    sparePartId: {
      type: String,
    },
  },
  data () {
    return {
      sparePartNum: '',
      workOrderNum: '',
      tableData: [],
      dateRange: [],
      columnList: [
        {
          tooltip: true,
          label: '工单编号',
          minWidth: '160px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '数量',
          minWidth: '150px',
          prop: 'sparePartNumber',
        },
        {
          tooltip: true,
          label: '客户',
          minWidth: '150px',
          prop: 'customerName',
        },
        {
          tooltip: true,
          label: '仓库',
          minWidth: '150px',
          prop: 'warehouseName',
        },
        {
          tooltip: true,
          label: '操作人',
          minWidth: '150px',
          prop: 'principalName',
        },
        {
          tooltip: true,
          label: '时间',
          minWidth: '150px',
          prop: 'signTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
    };
  },
  created () {
    this.handleSearch();
    this.getCount();
  },
  methods: {
    getCount () {
      getCount({ sparePartId: this.sparePartId }).then((rs) => {
        const { sparePartNum, workOrderNum } = rs.body;
        this.sparePartNum = sparePartNum;
        this.workOrderNum = workOrderNum;
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    async getTableList () {
      const param = {
        sparePartId: this.sparePartId,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      if (this.dateRange?.length) {
        param.startTime = this.dateRange[0];
        param.endTime = this.dateRange[1];
      }
      const rs = await getCountList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.tableData = list;
        this.pagination.total = total;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pos {
  margin-bottom: 10px;
  .countBox {
    display: inline-block;
    margin-right: 55px;
  }
  .timerRange {
    float: right;
  }
}
</style>
